/* Declaring Fonts */

@font-face {
  font-family: 'Le Jour';
  src: url('../fonts/LeJourScript.ttf');
}

@font-face {
  font-family: 'Kannada MN';
  src: url('../fonts/KannadaMN.ttc');
}

/* ----- GENERAL ----- */

body {
  padding-bottom: 200vh;
  background-color: #18191D;
}

h5 {
  font: 400 30px 'Baskerville';
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
}

p {
  font: 16px 'Kannada MN';
  text-transform: uppercase;
  margin: 30px 0;
}

h4 {
  font: 60px 'Playfair Display';
  margin: 30px;
}

a {
  font-family:'Kannada MN';
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  color: #d4cec2;
  border: 1.5px solid #d4cec2;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 40px 0;
}

h3 {
  font: 400 45px 'Baskerville';
  text-transform: uppercase;
  letter-spacing: 5px;
}

h6 {
  font: 400 16px 'Kannada MN';
  text-transform: uppercase;
  color: #ab9d65;
}

.cursive {
  font: 25px 'Le Jour';
  text-transform: lowercase;
  letter-spacing: 0.1px;
  position: relative;
  bottom: 5px;
}

section {
  text-align: end;
  padding: 30px;
}

h3 .cursive {
  position: relative;
  top: 20px;
  font-size: 30px;
}

section .cursive {
  color: #ab9d65;
}

section a {
  color: #ab9d65;
  border-color: #ab9d65;
  z-index: 100;
}

.smaller {
  font-size: 30px;
}

.slightDown {
  position: relative;
  top: 30px;
}

/* ----- LANGUAGE SELECTION SCREEN ----- */

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.language-container {
  background-color: #18191D;
  padding: 20px;
  position: fixed;
  left: 0;
  width: 91%;
  height: 100%;
}

.language-container.fade-out {
  animation: FadeOut 2s forwards;
}

.language-screen {
  color: #d4cec2;
  height: 95vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4cec2;
  border-radius: 10px;
  position: relative;
  animation: FadeIn 2s 1s backwards;
}

.language-screen p {
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-in-out;
  top: 160px;
}

.language-screen .visible {
  opacity: 1;
}


.language-screen .fade-in {
  animation: FadeIn 1s forwards;
}

.language-screen .fade-out {
  animation: FadeOut 1s forwards;
}

.language-button {
  font: 400 20px 'Baskerville';
  background-color: transparent;
  border: 1px solid #d4cec2;
  color: #d4cec2;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  margin: 10px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.language-one {
  animation: FadeIn 2s 1.5s backwards;
}

.language-two {
  animation: FadeIn 2s 2s backwards;
}

.language-three {
  animation: FadeIn 2s 2.5s backwards;
}

.language-button:hover {
  cursor: pointer;
}

.language-button:active {
  color: #ab9d65;
  border-color: #ab9d65;
}



/* ----- HERO SECTION ----- */

.hero-section {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-content: center;
  z-index: -1;
}

.hero-video-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-video-large {
  display: none;
}

.hero-content {
  position: relative;
  z-index: 1;
  color: #d4cec2;
  text-align: center;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d4cec2;
  border-radius: 10px;
  margin: 20px;
}

.monogram {
  width: 100px;
  position: absolute;
  top: 40px;
}

.hero-bottom {
  position: absolute;
  bottom: 60px;
}

/* Bounce animation for 'scroll down' */

@keyframes bounce {
  0%, 15%, 25%, 100% {
    transform: translateY(0);
  }
  10%, 20% {
    transform: translateY(-10px); 
  }

}

.scrollDown {
  font-family: 'Baskerville';
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0;
  transition: opacity 1s ease-out;
  margin: 0 auto;
  margin-top: 20px;
}

.scrollDown.visible {
  opacity: 1;
  animation: bounce 4s 15s infinite; 
}


/* Countdown */

.countdown {
  display: flex;
  justify-content: center;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
}

.countdown-number {
  font: 40px 'Playfair Display';
}

.countdown-unit {
  font: 15px 'Kannada MN';
}

/* ----- INVITE SECTION ----- */

.invite-section {
  background-color: #18191D;
  color: #d4cec2;
  text-align: center;
  height: 100vh;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  position: relative;
}

.line {
  width: 1px;
  height: 100px;
  background-color: #d4cec2;
  margin: 0 auto;
}

.invite-section .cursive {
  color: #ab9d65;
}

.invite-section h5 {
  font-size: 20px;
  margin: 0;
  position: relative;
  left: 5px;
}

.invite-section h4 {
  margin: 0px;
}

.invite-section p {
  margin: 0;
}

/* ----- VENUE SECTION ----- */

.venue-section {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
  position: relative;
  align-content: center;
}

.venue-section.at-top {
  position: fixed;
  top: 0;
  z-index: -1;
}

.venue-video-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.venue-video-larger {
  display: none;
}

.venue-content {
  z-index: 300px;
  position: absolute;
  top: 0px;
  height: 100%;
  text-align: center;
  width: 100%;
}

.venue-content * {
  color: #efefef;
}

.venue-content img {
  width: 250px;
  margin: 40px 0;
}

.venue-outline {
  border: 1px solid #d4cec2;
  border-radius: 10px;
  height: 88%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.venue-content p {
  margin: 15px 0;
}

/* ----- DETAILS SECTION ----- */

.details-section {
  background-color: #18191D;
  color: #d4cec2;
  border-radius: 10px;
  margin-top: 100vh;
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px;
}

/* ----- RSVP SECTION ----- */

.rsvp-section {
  background-image: url('./media/rsvpPhoto.jpeg');
  background-size: cover;
  height: 96vh;
  color: #d4cec2;
  padding: 20px;
  align-content: center;
  text-align: center;
  position: relative;
  z-index: -2;
}

.rsvp-section.at-top {
  position: fixed;
  top: 0;
  z-index: -1;
}

.rsvp-outline {
  border: 1px solid #d4cec2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.rsvp-section h5 .cursive {
  font-size: 20px;
}

.information-heading-german {
  font-size: 20px;
}

/* ----- FURTHER DETAILS ----- */

.further-details {
  position: relative;
  top: 100vh;
  background-color: #18191D;
  border-radius: 10px;
  color: #d4cec2;
  padding-bottom: 80px;
}

/* ----- CONTACT ----- */

.contact-section {
  background-image: url('./media/contactPhoto.jpeg');
  background-size: cover;
  background-position: center;
  background-color: #d4cec2;
  height: 100vh;
  color: #d4cec2;
  text-align: center;
  display: none;
  position: relative;
}

.contact-section.at-top {
  display: block;
  position: fixed;
  top: 0;
  z-index: -1;
}

.contact-outline {
  border: 1.5px solid #d4cec2;
  border-radius: 10px;
  margin: 20px;
  height: 95%;
  align-content: center;
  position: relative;
}

.directionsButton, .rsvpButton {
  height: 100vh;
  width: 90%;
  position: absolute;
  top: 100%;
  left: 0;
  cursor: default;
  border: none;
  color: transparent;
}

.contactButton {
  height: 100vh;
  width: 90%;
  position: absolute;
  bottom: -105vh;
  left: 0;
  cursor: default;
  border: none;
  color: transparent;
}

/* ----------- RESPONSIVE STYLING ---------- */

@media (min-width: 800px) {
  p {
    max-width: 700px;
  }

  .invite-section p {
    margin: 0 auto;
  }

  .further-details, .details-section {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .rsvp-section {
    background-image: url('./media/rsvpPhotoMedium.png');
    background-size: cover;
    width: 100%;
    filter: brightness(0.9);
  }

  .rsvp-outline {
    width: 95%;
    padding: 0;
    margin-right: 45px;
  }

  .contact-section {
    width: 100%;
  }

  .contact-section p {
    margin: 30px auto;
  }


  .language-container {
    width: 97%;
    margin: 0 auto;
  }

  .hero-video-mobile {
    display: none;
  }

  .hero-video-large {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .venue-video-mobile {
    display: none;
  }

  .venue-video-larger {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.6);
  }

  .countdown-number {
    font-size: 80px;
    margin: 0 40px;
  }
}